import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Header from "./header";
import Headroom from "react-headroom";
import TitleBox from "../components/title-box";
import HeroCaption from "../components/hero-caption";
import MobileHeader from "../components/mobile-header";
import { respondTo } from "../styles/respond-to";
import useMobile from "../utils/useMobile";

const StyledBackgroundImage = styled(BackgroundImage)`
  // background-position: center 30%;
  background-position: center ${(props) => props.position};
  background-size: cover;
  height: 30vw;
  z-index: 300;

  ${respondTo.sm`
    height: 60vw;
  `}

  + * {
    margin-top: 0;
  }
`;

const HeroPage = ({ image, title, caption, position, captionColor }) => {
  const isMobile = useMobile();

  return (
    <>
      {isMobile && <MobileHeader />}

      <StyledBackgroundImage Tag="div" fluid={image} position={position}>
        {!isMobile && (
          <Headroom>
            <Header />
          </Headroom>
        )}

        <TitleBox>{title}</TitleBox>
        <HeroCaption textColor={captionColor}>{caption}</HeroCaption>
      </StyledBackgroundImage>
    </>
  );
};

export default HeroPage;
