import styled from 'styled-components'

const HeroCaption = styled("p")`
  position: absolute;
  bottom: 1rem;
  margin-bottom: 0;
  right: 1rem;
  // color: white;
  color: ${props => props.textColor || "white"};
  font-size: 0.7rem;
  font-style: italic;
  text-align: right;
`;

export default HeroCaption;
