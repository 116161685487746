import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HeroPage from "../components/hero-page";
import Seo from "../components/seo";

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "FrancescaHenrichs.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      opengraphImage: file(relativePath: { eq: "FrancescaHenrichs.jpg" }) {
        sharp: childImageSharp {
          image: gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
    }
  `);

  return (
    <>
      <Seo
        title="Testimonials - Discover PA Six"
        description="What is like to live in North Central Pennsylvania."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Testimonials"
        caption=""
        position="30%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-text intro-text-home">
            <h3>Hannah Brock, Elk County</h3>
            <p>
              Currently the Branding and Licensing Manager for the PA Wilds.
              Hannah moved to St Marys, Elk County after graduating from
              college. She visited her grandparents and cousins here throughout
              her childhood and knew she wanted to live in a community that she
              loved and brought her joy.
            </p>
          </div>

          <div className="intro-text intro-text-video">
            <iframe
              title="Hannah Brock, Elk County"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/N3E49Kp21os"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-text intro-text-video video-reorder">
            <iframe
              title="Matthew Hileman, McKean County"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/q28P4ULF_bo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="intro-text intro-text-home">
            <h3>Matthew Hileman, McKean County</h3>
            <p>
              Matthew Hileman passionately speaks about the Marilyn Horne Museum
              and Exhibit Center and his love for museums and arts. Matthew
              moved to McKean County from Florida to find a quiet place to
              settle and work. Matthew speaks about the great things to do and
              see here but also the short distance that we are to more
              cosmopolitan places!
            </p>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-text intro-text-home">
            <h3>Sarah Nolan, Cameron County</h3>
            <p>
              Living in Emporium, Retired United States Marine Corps first
              Sergeant and Owner of Sarah Jane's Art Studio and Gathering Place,
              Sarah Nolan speaks of her experience becoming a resident and
              business owner in Cameron County, Pennsylvania.
            </p>
          </div>

          <div className="intro-text intro-text-video">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/cTqYqQaoBM0"
              title="Sarah Nolan, Cameron County"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-text intro-text-video video-reorder">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/xWVVbCuWbdw"
              title="Luke Lewis, Jefferson County"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="intro-text intro-text-home">
            <h3>Luke Lewis, Jefferson County</h3>
            <p>
              Luke is the Manufacturing Manager for Miller Fabrications at the
              Sandy Lake Facility. After earning a degree in Industrial
              Engineering, Luke returned to Jefferson County to live and work.
              One of the selling points that brought him back to the area was
              the opportunity to work with technology in manufacturing. Luke
              describes that his work in this small-town environment provides
              remarkable opportunities to work with advanced technology and
              equipment. Close proximity from his workplace to the park system
              provides him with easy access to his children’s sporting
              activities after work!
            </p>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-text intro-text-home">
            <h3>Michael Clement, Clearfield County</h3>
            <p>
              Michael was raised in DuBois, Clearfield County. Shortly after his
              high school graduation, Michael left the area to join the US Air
              Force, which was followed by a career in politics. Although he
              lived in places such as Las Vegas, Washington DC and Indonesia,
              Michael always called this area his home. He talks of how the
              region rallies around families in need and other matters of “home”
              that are meaningful for our community. Michael discusses the
              exciting opportunities the county offers in what is known as the
              third largest county by land in Pennsylvania... from dining to
              hiking and hunting, as well as easy access to Interstate 80.
            </p>
          </div>

          <div className="intro-text intro-text-video">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/G568VJ111eE"
              title="Michael Clement, Clearfield County"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-text intro-text-video video-reorder">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/jQhdSIvWqeE"
              title="Potter County, Pennsylvania Story"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="intro-text intro-text-home">
            <h3>Candace Hillyard and Sharon Hallstrom, Potter County</h3>
            <p>
              Living in Galeton, Candace Hillyard and Sharon Hallstrom talk
              about their experiences working and enjoying the beauty of Potter
              County. Candace is the Executive Director of the PA Route 6
              Alliance, one of the designated 12 Heritage Areas in Pennsylvania.
              Sharon is a Registered Radiologic Technologist who works at UPMC.
              Both women grew up in rural areas of North Central PA, and after
              college returned to the area. According to Sharon, living in
              Potter County can be summed up by the quote "Anywhere you go, you
              have an awesome view."
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default Testimonials;
