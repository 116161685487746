import styled from "styled-components";
import { respondTo } from "../styles/respond-to";

const TitleBox = styled("h1")`
  font-size: 2.5rem;
  position: absolute;
  bottom: 1rem;
  margin-bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  ${respondTo.sm`
    font-size: 1.6rem;
    bottom: 3rem;
  `}
`;

export default TitleBox;
